'use strict';

// Globals params
var app;
var perfomingRequest = false;
var fr = {
    "dateTime": "%A, le %e %B %Y, %X",
    "date": "%d/%m/%Y",
    "time": "%H:%M:%S",
    "periods": ["AM", "PM"],
    "days": ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    "shortDays": ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
    "months": ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
    "shortMonths": ["janv.", "févr.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."]
};
var headerData = {
    diffMentions: false,
    rankingDay: false,
    lastDate: false,
    firstDate: false
};
var _DATA = {
    chart: false,
    candidates: false,
    medias: false
};

var api;

var APP_EL = '#app';
var API_URL = 'https://tracker-api.datagif.fr/api/v1';

//Run
document.addEventListener('DOMContentLoaded',init);

function init() {
    
    if (typeof CATEGORY === 'undefined') return;

    //init axios
    api = axios.create({
        baseURL: API_URL,
        timeout: 10000,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });

    //Get Datas
    getHeaderData();

    var activeItem = document.querySelector('.section-charts .navigation-container .item.active button');
    let days = activeItem ? activeItem.dataset.days : null;
    getStatsData(days);
    app = initApp();

    if(document.querySelector("body").classList.contains("municipales")){
        // Select City Tracker
        var selectSelected = document.querySelector(".select--city__selected");
        var selectItems = document.querySelector(".select--city__items");
        var selectItem = document.querySelectorAll(".select--city__items a");
        var header = document.querySelector(".header-container");
        var selectCity = document.querySelector(".select--city");
        var selectCitySpan = document.querySelector(".select--city__selected__span");
    
        if (window.matchMedia("(max-width: 700px)").matches) {
            document.addEventListener("click", toggleDropdown);
        } else if (selectCity) {
            selectCity.addEventListener("mouseenter",function(){
                selectItems.classList.remove("select--city__items--hidden");
                selectSelected.classList.add("select-arrow-active");
                header.classList.add("header-container--contentHidden");


                // Change village on hover
                // pageName = selectCitySpan.innerHTML; 
                // for (let i = 0; i < selectItem.length; i++) {
                //     selectItem[i].addEventListener("mouseover",function(){
                //         selectCitySpan.innerHTML = selectItem[i].innerHTML;           
                //     })    
                // }

            })
            selectCity.addEventListener("mouseleave",function(){
                selectItems.classList.add("select--city__items--hidden");
                selectSelected.classList.remove("select-arrow-active");
                header.classList.remove("header-container--contentHidden");
                
                // selectCitySpan.innerHTML = pageName;
            })
        }

        function toggleDropdown(event) {

            if (event.target.classList.contains('select--city__selected')){
                selectItems.classList.toggle("select--city__items--hidden");
                selectSelected.classList.toggle("select-arrow-active");

                header.classList.toggle("header-container--contentHidden");
            } else {
                selectItems.classList.add("select--city__items--hidden");
                selectSelected.classList.remove("select-arrow-active");

                header.classList.remove("header-container--contentHidden");
            }
        }
    
    }
    

}

/**
 * Get Data for header in api
 */
var store_data = [];
function getHeaderData() {
    api.get('/ranking?category='+CATEGORY)
        .then(function (response) {

            headerData.diffMentions = response.data.diffMentions;
            headerData.rankingDay = response.data.rankingDay;

            var parseTime = d3.timeParse("%Y-%m-%d");
            var formatDate = d3.timeFormat("%d of %B");

            if (LOCALE == 'FR') {
                var d3fr = d3.timeFormatLocale(fr);
                formatDate = d3fr.format("%d %B");
            }

            headerData.firstDate = formatDate(parseTime(response.data.firstDate[0].date));

            var today = new Date();
            today.setHours(0, 0, 0, 0);
            
            var parsedDate = parseTime(response.data.lastDate[0].date);
            var text = "";

            if (parsedDate < today) {
                text = (LOCALE == 'FR') ? "Hier" : "Yesterday";
            } else {
                text = (LOCALE == 'FR') ? "Aujourd'hui" : "Today";
            }

            if (LOCALE == 'FR') {
                formatDate = d3fr.format("%A %d %B %Y");
            } else {
                formatDate = d3.timeFormat("%A %d of %B");
            }

            headerData.lastDate = {
                date: formatDate(parseTime(response.data.lastDate[0].date)),
                text: text
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}
function getStatsData(nb_day) {
    var day;
    
    switch (nb_day) {
        case "7":
            day = 7;
            break;
        case "30":
            day = 30;
            break;
        case "all":
            day = false;
            break;
        default:
            day = "all";
            break;
    }

    var find = false;
    for (var i = 0; i < store_data.length; i++) {
        if (store_data[i].days == day) {
            find = true;
            parseData(store_data[i].data);
        }
    }

    if (!find && !perfomingRequest) {
        perfomingRequest = true;
        var item = {
            days: day,
            data: null
        };
        api.get('/stats?day=' + day+'&category='+CATEGORY)
            .then(function (response) {
                parseData(response.data);
                item.data = response.data;
                store_data.push(item);
                perfomingRequest = false;
                if (init_graph) {
                    drawCandidatesGraph();
                    updateCandidatesGraph(_DATA.chart)
                }

            }).catch(function (error) {
            console.log(error);
        });
    }

    function parseData(data) {
        _DATA.chart = data.graph;
        var candidates = d3.nest()
            .key(function (d) {
                return d.candidate_id
            }).sortKeys(d3.ascending)
            .rollup(function (d) {
                return {
                    mentions: d3.sum(d, function (e) {
                        return e.mentions;
                    }),
                    name: cleanText(d[0].lastname),
                    raw: d
                }
            })
            .entries(data.candidates);
        candidates = candidates.sort(function (a, b) {
            if (a.value.mentions < b.value.mentions)
                return 1;
            if (a.value.mentions > b.value.mentions)
                return -1;
            return 0;
        });
        _DATA.candidates = candidates;
        _DATA.medias = d3.nest()
            .key(function (d) {
                return d.medias_name
            }).sortKeys(d3.ascending)
            .rollup(function (d) {
                return {
                    mentions: d3.sum(d, function (e) {
                        return e.mentions;
                    }),
                    name: cleanText(d[0].medias_name),
                    logo: d[0].medias_logo,
                    raw: d
                }
            })
            .entries(data.candidates)
    }
}

/**
 *
 * Vue.js init
 */
var candidateMentionsItem = {
    props: {
        candidate: Object
    },
    template: '<div class="item-content">' +
    '   <div class="item-front">' +
    '       <h4 class="candidate-mentions-name" v-html="candidate.value.name"></h4>' +
    '       <span class="candidate-mentions-max">{{ candidate.value.mentions }}</span>' +
    '       <svg ref="graph" class="candidate-mentions-graph"></svg>' +
    '   </div>' +
    '   <div class="item-back">' +
    '       <h4 class="candidate-mentions-name" v-html="candidate.value.name"></h4>' +
    '       <ul class="candidates-medias-mention">' +
    '           <li class="candidates-media" v-for="n in 5">' +
    '               <span class="candidates-media-value">{{candidate.value.raw[n-1].mentions}}</span>' +
    '               <span class="candidates-media-name">{{candidate.value.raw[n-1].medias_name}}</span>' +
    '           </li>' +
    '       </ul>' +
    '   </div>' +
    '</div>',
    mounted: function () {
        drawCandidatesGraphByCandidate(this.$refs.graph, this.candidate.key)
    },
    updated: function () {
        updateCandidatesGraphByCandidate(this.$refs.graph, this.candidate.key)
    }
};

function initApp() {
    return new Vue({
        el: APP_EL,
        data: function () {
            return {
                header: headerData,
                content: _DATA
            }
        },
        computed: {
            loading: function () {
                return !this.header.diffMentions && !this.content.chart
            },
            /**
             Zone Experiment Start
             */

            rankingAllFirst: function () {
                return this.header.rankingDay ?
                    {
                        
                    } : '';
            },


             /**
             Zone Experiment End
             */
            rankingFirst: function () {
                return this.header.rankingDay ?
                    {
                        name: this.header.rankingDay[0].firstname + ' ' + cleanText(this.header.rankingDay[0].lastname),
                        color: this.header.rankingDay[0].color,
                        mentions: this.header.rankingDay[0].today_mentions
                    } : '';
            },
            rankingSecond: function () {
                return this.header.rankingDay ?
                    {
                        name: this.header.rankingDay[1].firstname + ' ' + cleanText(this.header.rankingDay[1].lastname),
                        color: this.header.rankingDay[1].color,
                        mentions: this.header.rankingDay[1].today_mentions
                    } : '';
            },
            rankingThird: function () {
                return this.header.rankingDay ?
                    {
                        color: this.header.rankingDay[2].color,
                        name: this.header.rankingDay[2].firstname + ' ' + cleanText(this.header.rankingDay[2].lastname),
                        mentions: this.header.rankingDay[2].today_mentions
                    } : '';
            },
            mVsFWords: function () {
                return this.header.rankingDay ?
                    {
                        first: this.header.rankingDay[0].sex === 'M' ? 'mentionné' : 'mentionnée',
                        second: this.header.rankingDay[0].sex === 'M' ? 'il est suivi' : 'elle est suivie'
                    } : '';
            }
        },
        methods: {
            changeData: function (event) {
                var nb_days = event.target.dataset.days;
                getStatsData(nb_days);
                var activeChildren = document.querySelectorAll('.navigation-container .item');
                activeChildren.forEach(function (activeChild) {
                    if (activeChild.firstChild.getAttribute('data-days') != nb_days) {
                        activeChild.classList.remove('active');
                    } else {
                        activeChild.classList.add('active');
                    }
                });
            },
            toggleFlip: function (event) {
                var el = event.currentTarget;
                if (el.classList.contains('flipped')) {
                    el.classList.remove('flipped')
                } else {
                    el.classList.add('flipped')
                }
            },
            hoverMedia: function (event) {
                var el = event.currentTarget;
                var color = el.getAttribute('data-color');
                var id = el.getAttribute('data-id');

                var medias = document.querySelectorAll('.medias-mentions-container .medias-media');
                medias.forEach(function (mediaItem) {
                    if (mediaItem.getAttribute('data-id') == id) {
                        mediaItem.style.color = color
                    } else {
                        mediaItem.style.color = "#333333"
                    }
                })
            },
            leaveMedia: function (event) {
                var medias = document.querySelectorAll('.medias-mentions-container .medias-media');
                medias.forEach(function (mediaItem) {
                    mediaItem.style.color = "#333333"
                })
            }
        },
        components: {
            'candidate-mentions-component': candidateMentionsItem
        },
        mounted: function () {
            document.body.classList.remove('no-scroll');
        },
        updated: function () {
            if (!init_graph) {
                updateCandidatesGraph(this.content.chart);
            }
        }
    })
}

//GRAPHS
//D3 Globals variables

if (typeof d3 !== 'undefined') {

    var parseTime = d3.timeParse("%Y-%m-%d");
    var locale = d3.timeFormat("%d %b");

    var svg_width, svg_height;
    var lines = [];
    var mouseG;
    var margin = {top: 20, right: 20, bottom: 50, left: 20};
    var bottom_axis;
    var xAxis;
    var init_graph = true;
    var candidates_line_container;
    var graph_width, graph_height;
    var svg_el;
    var  x, y;

    window.onresize = resize;

}

function drawCandidatesGraph() {
    var data = _DATA.chart;
    var line, svg = d3.select("#candidates-chart");
    svg_el = document.getElementById("candidates-chart");

    graph_width = svg_el.getBoundingClientRect().width;
    graph_height = svg_el.getBoundingClientRect().height;

    svg_width = graph_width - margin.left - margin.right;
    svg_height = graph_height - margin.top - margin.bottom;

    var g = svg.append("g").attr('class', 'candidates-charts-box').attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    x = d3.scaleTime().range([0, svg_width]);
    y = d3.scaleLinear().range([svg_height, 0]);

    x.domain(d3.extent(data, function (d) {
        return parseTime(d.date);
    }));
    y.domain([0, d3.max(data, function (d) {
        return +d.mentions
    })]);

    candidates_line_container = g.append('g')
        .attr('class', 'candidates-graph-container');

    bottom_axis = g.append('g').attr('class', 'candidates-graph-bottom-axes');

    //Mouse initialisation
    mouseG = g.append("g")
        .attr("class", "mouse-over-effects");

    mouseG.append("path") // this is the black vertical line to follow mouse
        .attr("class", "mouse-line")
        .style("opacity", "0");

    g.append('svg:rect') // append a rect to catch mouse movements on canvas
        .attr('width', svg_width) // can't catch mouse events on a g element
        .attr('height', svg_height)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .on('mouseout', function () { // on mouse out hide line, circles and text
            d3.select(".mouse-line")
                .style("opacity", "0");
            d3.selectAll(".mouse-per-line circle")
                .style("opacity", "0");
            d3.selectAll(".mouse-per-line g")
                .style("opacity", "0");
        })
        .on('mouseover', function () { // on mouse in show line, circles and text
            d3.select(".mouse-line")
                .style("opacity", "1");
            d3.selectAll(".mouse-per-line circle")
                .style("opacity", "1");
            d3.selectAll(".mouse-per-line g")
                .style("opacity", "1");

        })
        .on('mousemove', function () {
            var mouse = d3.mouse(this);
            d3.select(".mouse-line")
                .attr("d", function () {
                    var d = "M" + mouse[0] + "," + svg_height;
                    d += " " + mouse[0] + "," + 0;
                    return d;
                });

            var mouseperline = d3.selectAll(".mouse-per-line");

            mouseperline.attr("transform", function (d, i) {
                var xDate = x.invert(mouse[0]),
                    bisect = d3.bisector(function (d) {
                        return d.date;
                    }).right,
                    idx = bisect(d.values, xDate);

                var beginning = 0,
                    end = lines[i].getTotalLength(),
                    target = null;

                while (true) {
                    target = Math.floor((beginning + end) / 2);
                    var pos = lines[i].getPointAtLength(target);
                    if ((target === end || target === beginning) && pos.x !== mouse[0]) {
                        break;
                    }
                    if (pos.x > mouse[0]) end = target;
                    else if (pos.x < mouse[0]) beginning = target;
                    else break; //position found
                }


                var group = d3.select(this).select('g');
                var rect = group.select('rect');
                rect
                    .attr("transform", "translate(0,-15)");

                d3.select(this).select('g .candidate-value')
                    .text(y.invert(pos.y).toFixed(0));

                return "translate(" + mouse[0] + "," + pos.y + ")";
            });

            var legends = d3.selectAll('.mouse-per-line-legend');
            legends
                .attr('transform', function (d, i, j) {
                    var legendWith = j[i].getBoundingClientRect().width;
                    var translateX = 10;
                    if (mouse[0] + legendWith + 10 > svg_width) {
                        translateX = -legendWith - 10
                    }
                    return "translate(" + translateX + ",0)";
                }).transition().ease(d3.easeBackOut).duration(250)

        });
}

function updateCandidatesGraph(data) {
    var svg = d3.select("#candidates-chart");
    var g = svg.select('.candidates-charts-box');

    graph_width = svg_el.getBoundingClientRect().width;
    graph_height = svg_el.getBoundingClientRect().height;

    svg_width = graph_width - margin.left - margin.right;
    svg_height = graph_height - margin.top - margin.bottom;

    x = d3.scaleTime().range([0, svg_width]);
    y = d3.scaleLinear().range([svg_height, 0]);

    x.domain(d3.extent(data, function (d) {
        return parseTime(d.date);
    }));
    y.domain([0, d3.max(data, function (d) {
        return +d.mentions
    })]);

    var line = d3.line()
        .x(function (d) {
            return x(parseTime(d.date));
        })
        .y(function (d) {
            return y(d.mentions);
        })
        .curve(d3.curveLinear);

    // Nested Data
    var dataNest = d3.nest()
        .key(function (d) {
            return d.candidate_id
        })
        .entries(data);

    //Lines
    var candidate_container = g.select('.candidates-graph-container');
    var candidate_lines = candidate_container.selectAll(".candidate-graph-line").data(dataNest, function (d) {
        return d.values
    });

    candidate_lines.enter()
        .append('path')
        .attr('class', 'candidate-graph-line')
        .attr("id", function (d, i) {
            return "candidate-graph-line" + i;
        })
        .attr("d", function (d) {
            return line(d.values);
        }).style('stroke', function (d) {
        return d.values[0].color
    })
        .style('stroke-width', 2.5);

    candidate_lines.exit().remove();

    var delay;
    if (init_graph) {
        delay = 700;
        init_graph = false
    } else {
        delay = 0
    }

    d3.selectAll(".candidate-graph-line").style("opacity", "0");
    d3.selectAll(".candidate-graph-line").each(function (d, i) {
        // Get the length of each line in turn
        if (d3.select("#candidate-graph-line" + i).node() != null) {
            var totalLength = d3.select("#candidate-graph-line" + i).node().getTotalLength();
            d3.selectAll("#candidate-graph-line" + i).attr("stroke-dasharray", totalLength + " " + totalLength)
                .attr("stroke-dashoffset", totalLength)
                .transition()
                .duration(1500)
                .delay(delay)
                .ease(d3.easeCubicInOut)
                .attr("stroke-dashoffset", 0)
                .style("opacity", "1")
        }
    });

    //Axis
    xAxis = d3.axisBottom(x)
        .ticks(d3.timeDay.every(1))
        .tickFormat(locale);

    var pos = graph_height - margin.bottom - margin.top;
    bottom_axis
        .attr("transform", "translate(0," + pos + ")")
        .transition().duration(1500).ease(d3.easeExpInOut)
        .call(xAxis)
        .selectAll("text")
        .style("text-anchor", "end")
        .attr('class', 'x-axis--label')
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", function (d) {
            return "rotate(-65)";
        });

    //Mouse
    lines = document.getElementsByClassName('candidate-graph-line');
    var mousePerLine = mouseG.selectAll('.mouse-per-line')
        .data(dataNest)
        .enter()
        .append("g")
        .attr("class", "mouse-per-line");

    mousePerLine.append("circle")
        .datum(function (d) {
            return {id: d.id, value: d.values[d.values.length - 1]};
        })
        .attr("class", "mouse-per-line-circle")
        .attr("r", 7)
        .style("fill", function (d) {
            return d.value.color
        })
        .style("stroke-width", "1px")
        .style("opacity", "0");

    var group = mousePerLine
        .append("g")
        .datum(function (d) {
            return {id: d.id, value: d.values[d.values.length - 1]};
        })
        .attr("class", "mouse-per-line-legend")
        .style("fill", "#fffff")
        .attr("transform", "translate(10,0)")
        .style("opacity", "0");

    group.append("text")
        .attr("transform", "translate(30, 2.5)")
        .attr("class", "candidate-name")
        .attr("id", function (d, i) {
            return "candidate-name-" + i
        })
        .attr("font-weight", "400")
        .attr("font-size", "13px")
        .style("fill", "#ffffff")
        .text(function (d) {
            return d.value.lastname
        });

    group.append("text")
        .attr("transform", "translate(5, 2.5)")
        .attr("class", "candidate-value")
        .style("fill", "#ffffff")
        .attr("font-weight", "400")
        .attr("font-size", "13px");

    group.insert("rect", ":first-child")
        .attr("width", function (d, i) {
            var width = group.select("#candidate-name-" + i).node().getComputedTextLength();
            return Math.round(width) + 35
        })
        .attr("height", "25")
        .attr("transform", "translate(0, 0)")
        .style("fill", function (d) {
            return d.value.color
        })
        .style("opacity", "0.9");


    mousePerLine.exit().remove();
    group.exit().remove();
}

function drawCandidatesGraphByCandidate(container, id) {
    var data = _DATA.chart,
        svg = d3.select(container),
        width = container.getBoundingClientRect().width,
        height = container.getBoundingClientRect().height,
        x = d3.scaleTime().range([0, width]),
        y = d3.scaleLinear().range([height, 0]),
        parseTime = d3.timeParse("%Y-%m-%d");

    //Scale domain
    x.domain(d3.extent(data, function (d) {
        return parseTime(d.date);
    }));
    y.domain([0, d3.max(data, function (d) {
        return +d.mentions
    })]);

    var line = d3.line()
        .x(function (d) {
            return x(parseTime(d.date));
        })
        .y(function (d) {
            return y(d.mentions);
        })
        .curve(d3.curveLinear);

    data = _DATA.chart.filter(function (d) {
        return d.candidate_id == id
    });

    var color = data[0].color;

    //CreateGraph
    var g = svg.append("g").attr('class', 'graph-mentions').attr("transform", "translate(0,0)");

    var candidate_line = g.append("path")
        .attr("class", "candidate-mention-line")
        .transition()
        .duration(500)
        .ease(d3.easeCircle)
        .attr("d", line(data))
        .style('stroke', color)
        .style('stroke-width', 2.5)
        .style('fill', 'none');
}

function updateCandidatesGraphByCandidate(container, id) {
    var data = _DATA.chart,
        svg = d3.select(container),
        width = container.getBoundingClientRect().width,
        height = container.getBoundingClientRect().height,
        x = d3.scaleTime().range([0, width]),
        y = d3.scaleLinear().range([height, 0]),
        parseTime = d3.timeParse("%Y-%m-%d");

    //Scale domain
    x.domain(d3.extent(data, function (d) {
        return parseTime(d.date);
    }));
    y.domain([0, d3.max(data, function (d) {
        return +d.mentions
    })]);

    var line = d3.line()
        .x(function (d) {
            return x(parseTime(d.date));
        })
        .y(function (d) {
            return y(d.mentions);
        })
        .curve(d3.curveLinear);

    data = _DATA.chart.filter(function (d) {
        return d.candidate_id == id
    });

    //UpdateGraph
    var g = svg.select('.graph-mentions').transition();
    g.select('.candidate-mention-line')
        .duration(750)
        .ease(d3.easeCubicInOut)
        .attr("d", line(data));
}

//UTILS
function cleanText(txt) {
    txt = txt.trim().replace(' ', '&nbsp;');
    return txt.trim().replace(/\s(:|;|%|€|\$|°C|°F|»|\!|\?|–)/, '&nbsp;$1');
}

function resize() {
    updateCandidatesGraph(_DATA.chart)
}



